
import { Answer } from "@/models/Answer";
import { computed, nextTick, ref } from "vue";

export default {
  name: "MultiNumber",

  // * Properties
  props: {
    answersList: {
      required: true,
      type: Array
    }, 
    
    width: {
      default: "",
      type: String,
    },
  },

  // * Emits
  emits: ['next'],

  // * Setup
  setup(props, { emit }) {
    // * Computed
    const answers = computed(() => props.answersList as Array<Answer>);
    const enabledSubmitBtn = computed(() => selectedAnswers.value.length > 0);

    // * Data
    const selectedAnswers = ref<Array<number>>([]);

    // * Methods
    const next = function() {
      const answersWithValues = {};

      let i = 0;
      answers.value.forEach((elem: any) => {
        const answer = elem as Answer;
        answersWithValues[answer.id] = selectedAnswers.value[i];

        i += 1;
      });

      emit("next", answersWithValues);
    }

    // * Init
    nextTick(async () => {
      props.answersList.forEach(() => selectedAnswers.value.push(0));      
    });

    return {
      // * Computed
      answers,
      enabledSubmitBtn,

      // * Data
      selectedAnswers,

      // * Methods
      next,
      // onChange,
    }
  }
}
