import { Question } from "@/models/Question";
import { Questionnaire } from "@/models/UserQuestionnaires";
import { apiGet, apiPost } from "./api-service";

export class QuestionnaireService {
  static async test(email: string): Promise<any> {
    return await apiPost(`/campaigns/test`, { 'email': email });
  }

  static async fetchQuestions(id: string): Promise<Array<Question>> {
    const response = await apiGet(`/user_questionnaires/${id}/questions`);
    return response.data.questions as Array<Question>;
  }

  static async show(id: string): Promise<Questionnaire> {
    const response = await apiGet(`/user_questionnaires/${id}`);
    return response.data.user_questionnaire as Questionnaire;
  }

  static async first(id: string): Promise<Questionnaire> {
    const response = await apiGet(`/user_questionnaires/${id}/first`);
    return response.data.user_questionnaire as Questionnaire;
  }

  static async next(id: string, params: Map<string, any>): Promise<Questionnaire> {
    const response = await apiPost(`/user_questionnaires/${id}/next`, params);
    return response.data.user_questionnaire as Questionnaire;
  }
}