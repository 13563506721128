
import { Answer } from "@/models/Answer";
import { computed, ref } from "vue";

export default {
  name: "SingleFor",

  // * Properties
  props: {
    answersList: {
      required: true,
      type: Array
    }, 

    for_question_labels: {
      required: true,
      type: Array
    }, 

    width: {
      default: "",
      type: String,
    },
  },

  // * Emits
  emits: ['next'],

  // * Setup
  setup(props, { emit }) {
    // * Computed
    const answers = computed(() => { 
      return (props.answersList as Array<Answer>).map((answer) => answer.labels);
    });

    const enableSubmitBtn = computed(() => selectedAnswers.value.filter((elem) => elem != null).length === forQuestionLabels.value.length);

    const forQuestionLabels = computed(() => props.for_question_labels as Array<string>);

    // * Data
    const selectedAnswers = ref<Array<Answer>>([]);

    // * Methods
    const next = function() {
      const answersWithValues = {};

      let i = 0;
      forQuestionLabels.value.forEach((label: string) => {
        answersWithValues[label] = selectedAnswers.value[i];

        i += 1;
      });

      emit("next", answersWithValues);
    }

    // const selectAnswer = function (answer: Answer, index: number) {
      
    //   selectedAnswers.value[index] = answer;
    // }

    return {
      // * Computed
      answers,
      enableSubmitBtn,
      forQuestionLabels,

      // * Data
      // selectAnswer,
      selectedAnswers,

      // * Methods
      next,
      // onChange,
    }
  }
}
