
import { Answer } from "@/models/Answer";
import { computed, nextTick, onActivated, onMounted, onUpdated, ref } from "vue";

export default {
  name: "SingleSelect",

  // * Properties
  props: {
    answersList: {
      required: true,
      type: Array
    }, 
    
    width: {
      default: "",
      type: String,
    },
  },

  // * Emits
  emits: ['next'],

  // * Init
  setup(props, { emit }) {
    
    // * Computed
    const answers = computed(() => props.answersList as Array<Answer>);
    const enableSubmitBtn = computed(() => selectedAnswer.value !== null && (!otherAnswerSelected.value || otherVal.value.length > 0));
    const otherVal = ref('');

    // * Data
    const otherAnswerSelected = ref(false);
    const selectedAnswer = ref<Answer | null>(null);

    // * Methods
    const next = function() {
      const answer = Object.assign({}, selectedAnswer.value);
      if (otherAnswerSelected.value) {
        (answer as Answer).other_text = otherVal.value;
        console.log(answer);
      }
      emit("next", answer);
    }

    const selectAnswer = function (answer: Answer)  {
      selectedAnswer.value = answer;
      otherAnswerSelected.value = (answer.open_answer ?? false);
    }

    return {
      // * Computed
      answers,
      enableSubmitBtn,

      // * Data
      otherAnswerSelected,
      otherVal,
      selectedAnswer,

      // * Methods
      next,
      selectAnswer,
    }
  },
}
