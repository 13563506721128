import { Report } from "@/models/Report";
import { ReportService } from "@/services/api/report-service";


export interface ReportState {
  report: Report | null,
}

export const REPORTS_GETTERS = {
  CURRENT_REPORT: 'reports/currentReport',
};

export const REPORT_ACTIONS = {
  SHOW: 'reports/show',
}


export default {
  namespaced: true,

  state: (): ReportState => ({
    report: null
  }),

  actions: {
    async show(context: any, id: string) {
      return ReportService.show(id).then(
        result => {
          context.commit('setReport', result);
        },
        error => {
          console.error(error);
          context.commit('setReport', null);
        });
    },
  },

  getters: {
    currentReport: (state: ReportState) => state.report,
  },

  mutations: {
    setReport(state: ReportState, report: Report) {
      state.report = report;
    },
  }
}

