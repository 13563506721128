
import router from "@/router";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "QuestionnaireSubmissionView",

  setup() {
    // * Helpers

    // * Data
    const language = ref({ label: "Italiano", value: "it" });
    const languageOptions = [
      { label: "Italiano", value: "it" },
      { label: "Inglese", value: "en" },
    ];

    // * Methods
    const goToThankyoupage = async function () {

      router.push({ name: "thankyoupage" });
    };

    return {
      // * Data
      language,
      languageOptions,

      // * Methods
      goToThankyoupage
    };
  },
});
