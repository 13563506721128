
import { defineComponent } from "vue";

export default defineComponent({
  name: "SubmitBtn",

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },

    label: {
      required: true,
      type: String,
    },

    width: {
      default: "",
      type: String,
    },
  },
});
