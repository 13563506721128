import router from "@/router";
import axios from "axios";


const AUTH_HEADERS_KEY = 'headers';

function getHeaders(): any {
  const headers = localStorage.getItem(AUTH_HEADERS_KEY);
  return headers ? JSON.parse(headers) : null;
}

function initRequest() {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URI;
  console.log(`[ApiService] | baseURL: ${axios.defaults.baseURL}`);

  setHeaders();
}

function setHeaders() {
  const headers = getHeaders();
  if (headers) {
    axios.defaults.headers.common["access-token"] = headers["access-token"];
    axios.defaults.headers.common["client"] = headers["client"];
    axios.defaults.headers.common["uid"] = headers["uid"];
    axios.defaults.headers.common["token-type"] = headers["token-type"];

    return {
      'access-token': headers["access-token"],
      'client': headers["client"],
      'uid': headers["uid"],
      'token-type': headers["token-type"]
    };
  }
  return {};
}

export function storeHeaders(headers: string | null) {
  if (headers !== null) {
    localStorage.setItem(AUTH_HEADERS_KEY, headers);
  }
  else {
    localStorage.removeItem(AUTH_HEADERS_KEY);
  }
}


/**
 * HTTP Methods
 */
export function apiGet(resource: string, queryParams?: Map<string, any>): Promise<any> {
  const queryParamsArray: Array<string> = [];
  if (queryParams != null) {
    for (const key in queryParams.keys) {
      queryParamsArray.push([key, queryParams[key]].join("="));
    }
  }

  const url = queryParams !== undefined ? `${resource}?${queryParamsArray.join("&")}` : resource;
  console.log(`[ApiService] | GET ${url}`);

  initRequest();
  return axios.get(url);
}

export function apiPost(resource: string, params: Map<string, any> | any): Promise<any> {
  console.log(`[ApiService] | POST ${resource}`);

  initRequest();
  return axios.post(resource, params);
}

export function apiDelete(resource: string): Promise<any> {
  console.log(`[ApiService] | DELETE ${resource}`);

  initRequest();
  return axios.delete(resource);
}

export function apiPut(resource: string, params: Map<string, any>): Promise<any> {
  console.log(`[ApiService] | PUT ${resource}`);

  initRequest();
  return axios.put(resource, params);
}

export function apiPostMultipart(resource: string, formData: FormData): Promise<any> {
  console.log(`[ApiService] | POST MULTIPART ${resource}`);

  const headers = getHeaders();
  return axios.post(resource, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'access-token': headers["access-token"],
      'client': headers["client"],
      'uid': headers["uid"],
      'token-type': headers["token-type"],
    }
  });
}



/**
 * Axios Interceptors
 */
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Redirect /login in caso di risposta 401 o 403 delle api
    if (error && (error.response.status === 401 || error.response.status === 403)) {
      localStorage.clear();
      router.push({ name: 'questionario', params: {} })
    }

    if (error && (error.response.status === 409)) {
      localStorage.clear();
      router.push({ name: 'questionarioTerminato', params: { 'expired_at': error.response.data['errors'][0] } })
    }

    // TODO:
    // if (error.response.status === 404) {
    //   showToast(toast, { message: "Record not found", error: true });
    // }
    return Promise.reject(error.response);
    // return Promise.reject(Object.values(error.response.data.errors));
  },
);

