
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ThankyoupageView",

  setup() {
    // * Helpers

    // * Data
    const language = ref({ label: "Italiano", value: "it" });
    const languageOptions = [
      { label: "Italiano", value: "it" },
      { label: "Inglese", value: "en" },
    ];

    // * Methods

    return {
      // * Data
      language,
      languageOptions,

      // * Methods
    };
  },
});
