
import { computed, defineComponent, nextTick, reactive, ref } from "vue";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { email, sameAs, requiredIf } from "@vuelidate/validators";
import { AUTH_LOGIN, AUTH_SEND_CREDENTIALS } from "@/store/modules/auth";
import {
  errorCallback,
  hideLoader,
  showLoader,
  showToast,
} from "@/helpers/helpers";
import router from "@/router";
import { Questionnaire } from "@/models/UserQuestionnaires";
import { QUESTIONNAIRES_ACTIONS, QUESTIONNAIRES_GETTERS } from "@/store/modules/questionnaires";

export default defineComponent({
  name: "LoginView",

  setup() {
    const store = useStore();
    const toast = useToast();

    // * Data
    const formStep = ref(2);

    const formState = reactive({
      email: "",
      password: "",
      privacy: null,
      terms: null,
    });

    const formRules = {
      email: {
        required: requiredIf(formStep.value == 1),
        email,
      },
      password: {
        required: requiredIf(formStep.value == 2),
      },
      terms: {
        required: requiredIf(formStep.value == 2),
        sameAs: sameAs(true),
      },
      privacy: {
        required: requiredIf(formStep.value == 2),
        sameAs: sameAs(true),
      },
    };

    const formSubmitted = ref(false);
    const submitBtnStep2Disabled = ref(true);

    const passwordChar0 = ref(null as any);
    const passwordChar1 = ref(null as any);
    const passwordChar2 = ref(null as any);
    const passwordChar3 = ref(null as any);
    const passwordChar4 = ref(null as any);
    const passwordChars = ref(["", "", "", "", ""]);

    const v$ = useVuelidate(formRules, formState);

    // * Computed
    const currentUser = computed(() => store.getters["auth/currentUser"]);
    
    const questionnaire = computed(
      (): Questionnaire =>
        store.getters[QUESTIONNAIRES_GETTERS.CURRENT_QUESTIONNAIRE]
    );

    // * Methods
    const codeKeyDown = function (e, index: number) {
      if (e.keyCode == 8) {
        // Backspace
        switch (index) {
          case 1:
            if (passwordChar1.value!.$el.value == "") {
              passwordChar0.value!.$el.focus();
            }
            break;
          case 2:
            if (passwordChar2.value!.$el.value == "") {
              passwordChar1.value!.$el.focus();
            }
            break;
          case 3:
            if (passwordChar3.value!.$el.value == "") {
              passwordChar2.value!.$el.focus();
            }
            break;
          case 4:
            if (passwordChar4.value!.$el.value == "") {
              passwordChar3.value!.$el.focus();
            }
            break;
          default:
            break;
        }
      }
    };

    const codeKeyUp = function (e, index: number) {
      if (e.keyCode > 47 && e.keyCode < 58) {
        // Accetto solo numeri
        formState.password = `${passwordChar0.value!.$el.value}${
          passwordChar1.value!.$el.value
        }${passwordChar2.value!.$el.value}${passwordChar3.value!.$el.value}${
          passwordChar4.value!.$el.value
        }`;

        switch (formState.password.trim().length) {
          case 1:
            passwordChar1.value!.$el.focus();
            break;
          case 2:
            passwordChar2.value!.$el.focus();
            break;
          case 3:
            passwordChar3.value!.$el.focus();
            break;
          case 4:
            passwordChar4.value!.$el.focus();
            break;
          default:
            break;
        }
      } else {
        switch (index) {
          case 0:
            passwordChar0.value!.$el.value = "";
            break;
          case 1:
            passwordChar1.value!.$el.value = "";
            break;
          case 2:
            passwordChar2.value!.$el.value = "";
            break;
          case 3:
            passwordChar3.value!.$el.value = "";
            break;
          case 4:
            passwordChar4.value!.$el.value = "";
            break;
          default:
            break;
        }
      }
    };

    const handleSubmit = (isFormValid) => {
      formSubmitted.value = true;

      if (formStep.value == 2 && formState.password.length != 5) {
        return;
      } else if (!isFormValid) {
        return;
      }

      const params = {
        user_questionnaire_id: questionnaire.value.id,
        password: formState.password,
      };

      if (formStep.value == 1) {
        // vecchia gestione // Richiesta codice
        // store
        //   .dispatch(AUTH_SEND_CREDENTIALS, params)
        //   .then(() => {
        //     hideLoader(store);

        //     formStep.value = 2;

        //     showToast(toast, {
        //       message: "Ti abbiamo mandato il codice di accesso.",
        //       title: "Controlla la mail",
        //     });

        //     setTimeout(() => passwordChar0.value!.$el.focus(), 1000);
        //   })
        //   .catch((err) => {
        //     hideLoader(store);
        //     errorCallback(toast, err);
        //   });
      } else {
        showLoader(store);
        // * Login
        store
          .dispatch(AUTH_LOGIN, params)
          .then(() => {
            hideLoader(store);

            // * --> Onboarding FIXME:
            router.push({ name: "onboarding" });
          })
          .catch((err) => {
            hideLoader(store);
            errorCallback(toast, err);
          });
      }
    };
    const fetchQuestionnaire = async function () {
      try {
        const result = await store.dispatch(
          QUESTIONNAIRES_ACTIONS.SHOW
        );
        hideLoader(store);
        return result;
      } catch (err) {
        hideLoader(store);
        errorCallback(toast, err);
      }
    };

    // * Init
    nextTick(async () => {
      if (questionnaire.value == null){
        await fetchQuestionnaire();
      }
    });

    return {
      // * Data
      formStep,
      formSubmitted,
      passwordChar0,
      questionnaire,
      submitBtnStep2Disabled,
      formState,
      v$,

      // * Computed
      currentUser,

      // * Methods
      codeKeyDown,
      codeKeyUp,
      handleSubmit,
    };
  },
});
