import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57ce047c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12 grid flex-wrap m-0 p-0" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "col-12 m-0 p-0 mt-0 mb-4" }
const _hoisted_4 = { class: "flex justify-content-end mt-4 lg:mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubmitBtn = _resolveComponent("SubmitBtn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.answers, (answer) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["answer-single mr-2 mb-2", { 'selected': $setup.selectedAnswer != null && $setup.selectedAnswer.id == answer.id}]),
          onClick: ($event: any) => ($setup.selectAnswer(answer)),
          key: answer.id
        }, _toDisplayString(answer.labels.label_it), 11, _hoisted_2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SubmitBtn, {
          label: "Ok",
          disabled: !$setup.enableSubmitBtn,
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.enableSubmitBtn ? $setup.next() : false))
        }, null, 8, ["disabled"])
      ])
    ])
  ], 64))
}