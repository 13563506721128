import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '@/views/auth/LoginView.vue'
import TestView from '@/views/test/TestView.vue'
import OnboardingView from '@/views/onboarding/OnboardingView.vue'
import OnboardingStep2View from '@/views/onboarding/OnboardingStep2View.vue'
import QuestionnaireView from '@/views/questionnaire/QuestionnaireView.vue'
import ThankyoupageView from '@/views/thankyoupage/ThankYouPageView.vue'
import ReportView from '@/views/report/ReportView.vue'
import QuestionarioTerminatoView from '@/views/questionario_terminato/QuestionarioTerminatoView.vue'
import QuestionnaireSubmissionView from '@/views/questionnaire/QuestionnaireSubmissionView.vue'
import { User } from '@/models/User'

const routes: Array<RouteRecordRaw> = [
  {
    component: TestView,
    meta: {
      layout: 'AuthLayout',
      guest: true,
    },
    name: 'test',
    path: '/test-hLPCHMFQToa8U6C8X9nP',
  },
  {
    component: QuestionnaireView,
    name: 'root',
    meta: {
      layout: 'QuestionnaireLayout',
      requiresAuth: false
    },
    path: '/',
  },
  {
    component: LoginView,
    meta: {
      layout: 'AuthLayout',
      guest: true,
    },
    name: 'login',
    path: '/login',
  },

  // * Onboarding
  {
    component: OnboardingView,
    name: 'onboarding',
    meta: {
      layout: 'OnboardingLayout',
      requiresAuth: false
    },
    path: '/onboarding',
  },
  {
    component: OnboardingStep2View,
    name: 'onboarding-step2',
    meta: {
      layout: 'OnboardingLayout',
      requiresAuth: false
    },
    path: '/onboarding-2',
  },

  // * Questionario
  {
    component: QuestionnaireView,
    name: 'questionario',
    meta: {
      layout: 'QuestionnaireLayout',
      requiresAuth: false
    },
    path: '/questionario',
  }
  ,
  // Link usato per mail
  {
    component: QuestionnaireView,
    name: 'questionarioLink',
    meta: {
      layout: 'QuestionnaireLayout',
      requiresAuth: false
    },
    path: '/questionario/:id',
  },
  // Submission page
  {
    component: QuestionnaireSubmissionView,
    name: 'submission',
    meta: {
      layout: 'OnboardingLayout',
      requiresAuth: false
    },
    path: '/submission',
  },
  // Thank you page
  {
    component: ThankyoupageView,
    name: 'thankyoupage',
    meta: {
      layout: 'OnboardingLayout',
      requiresAuth: false
    },
    path: '/thankyoupage',
  },
  // Questionario terminato
  {
    component: QuestionarioTerminatoView,
    name: 'questionarioTerminato',
    meta: {
      layout: 'OnboardingLayout',
      requiresAuth: false
    },
    path: '/questionario-terminato',
  },
  // Dashboard
  {
    component: ReportView,
    name: 'report',
    meta: {
      layout: 'OnboardingLayout',
      requiresAuth: false
    },
    path: '/report/:id',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router



router.afterEach((route) => {
  // * Redirect guest / loggato
  const localStorageUser = localStorage.getItem('user');
  const storedUser = localStorageUser ? JSON.parse(localStorageUser) as User : null;


  if (route.meta.requiresAuth && storedUser === null) {
    // * --> Login
    router.push({ name: 'login' });
  }
  else {
    if (route.meta.guest && storedUser !== null) {
      // * --> Onboarding
      router.push({ name: 'onboarding' });
    }
  }
});
