
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "QuestionarioTerminatoView",

  setup() {
    // * Helpers

    // * Data
    const route = useRoute();
    const language = ref({ label: "Italiano", value: "it" });
    const languageOptions = [
      { label: "Italiano", value: "it" },
      { label: "Inglese", value: "en" },
    ];

    
    const expiredAt = ref('');
    expiredAt.value = `${route.params['expired_at']}`;

    // * Methods

    return {
      // * Data
      language,
      languageOptions,
      expiredAt,

      // * Methods
    };
  },
});
