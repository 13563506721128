import { sleep } from "@/helpers/helpers";
import { Question } from "@/models/Question";
import { Questionnaire } from "@/models/UserQuestionnaires";
import router from "@/router";
import { QuestionService } from "@/services/api/question-service";
import { QuestionnaireService } from "@/services/api/questionnaire-service";

const QUESTIONNAIRE_ID_KEY = 'questionnaire_id';
const QUESTIONNAIRE_KEY = 'questionnaire';
export interface QuestionnaireState {
  questions: Array<Question>,
  questionnaire: Questionnaire | null,
  questionnaireId: string | null,
}

export const QUESTIONNAIRES_GETTERS = {
  CURRENT_QUESTIONNAIRE: 'questionnaires/currentQuestionnaire',
  QUESTIONNAIRE_ID: 'questionnaires/questionnaireId',
  CURRENT_QUESTION: 'questionnaires/currentQuestion'
};

export const QUESTIONNAIRES_ACTIONS = {
  TEST: 'questionnaires/test',
  SHOW: 'questionnaires/show',
  FETCH_ALL_QUESTIONS: 'questionnaires/fetchAllQuestions',
  FIRST_QUESTION: 'questionnaires/first',
  NEXT_QUESTION: 'questionnaires/next',
  STORE_ID: 'questionnaires/storeQuestionnaireId',
  STORE_QUESTIONNAIRE: 'questionnaires/storedQuestionnaire',
}

// Carico il questionario salvato dal localStorage, se presente e lo inizializzo nello store
const localStorageQuestionnaireId = localStorage.getItem(QUESTIONNAIRE_ID_KEY);
const storedQuestionnaireId = localStorageQuestionnaireId ? localStorageQuestionnaireId as string : null;

const localStorageQuestionnaire = localStorage.getItem(QUESTIONNAIRE_KEY);
const storedQuestionnaire = localStorageQuestionnaire ? JSON.parse(localStorageQuestionnaire) as Questionnaire : null;

export default {
  namespaced: true,

  state: (): QuestionnaireState => ({
    questions: [],
    questionnaire: storedQuestionnaire,
    questionnaireId: storedQuestionnaireId
  }),

  actions: {
    async fetchAllQuestions(context: any) {
      QuestionnaireService.fetchQuestions(`${context.state.questionnaireId}`).then(
        result => {
          context.commit('setQuestions', result);
        },
        error => {
          console.error(error);
          context.commit('setQuestions', []);
        });
    },

    async show(context: any) {
      return QuestionnaireService.show(`${context.state.questionnaireId}`).then(
        result => {
          context.commit('storeQuestionnaireId', result.id);
          context.commit('setQuestionnaire', result);
        },
        error => {
          console.error(error);
          context.commit('storeQuestionnaireId', null);
          context.commit('setQuestionnaire', null);
        });
    },

    async first(context: any) {
      return QuestionnaireService.first(`${context.state.questionnaireId}`).then(
        result => {
          context.commit('setQuestionnaire', result);
        },
        error => {
          console.error(error);
          context.commit('setQuestionnaire', null);
        });
    },

    async test(context: any, email: string) {
      return QuestionnaireService.test(email)
    },

    async next(context: any, params: Map<string, any>) {
      return QuestionnaireService.next(`${context.state.questionnaireId}`, params).then(
        async (result) => {
          context.commit('setQuestionnaire', null);
          await sleep(500);
          context.commit('setQuestionnaire', result);
          if (result.is_completed) {
            router.push({ name: "submission" });
          }
        },
        error => {
          console.error(error);
          context.commit('setQuestionnaire', null);
        });

    },

    async storeQuestionnaireId(context: any, questionnaireId: string) {
      context.commit('storeQuestionnaireId', questionnaireId);
    }
  },

  getters: {
    currentQuestion: (state: QuestionnaireState) => {
      if (state.questionnaire != null && state.questions != null) {
        return state.questions.filter((q) => q.id == state.questionnaire?.current_question_id)[0];
      }
      return null
    },

    currentQuestionnaire: (state: QuestionnaireState) => state.questionnaire,

    questionnaireId: (state: QuestionnaireState) => state.questionnaireId,
  },

  mutations: {
    setQuestions(state: QuestionnaireState, questions: Array<Question>) {
      state.questions = questions;
    },

    setQuestionnaire(state: QuestionnaireState, questionnaire: Questionnaire) {
      state.questionnaire = questionnaire;
      localStorage.setItem(QUESTIONNAIRE_KEY, JSON.stringify(questionnaire));
      console.log(localStorage.getItem(QUESTIONNAIRE_KEY), 'LOCAL STORAGE QUESTIONARIO');
    },

    storeQuestionnaireId(state: QuestionnaireState, questionnaireId: string) {
      state.questionnaireId = questionnaireId;
      localStorage.setItem(QUESTIONNAIRE_ID_KEY, questionnaireId);
      console.log(localStorage.getItem(QUESTIONNAIRE_ID_KEY), 'LOCAL STORAGE KEY');
    }
  }
}

