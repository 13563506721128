import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39f8cf29"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "answer-single-for grid align-items-center justify-content-between mb-4" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "mt-0 mb-1" }
const _hoisted_4 = { class: "col-12 m-0 p-0 mb-4" }
const _hoisted_5 = { class: "flex justify-content-end mt-4 lg:mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_SubmitBtn = _resolveComponent("SubmitBtn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.forQuestionLabels, (label, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-12 m-0 p-0",
        key: `label-${index}`
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h4", _hoisted_3, _toDisplayString(label), 1),
            _createVNode(_component_Dropdown, {
              modelValue: $setup.selectedAnswers[index],
              "onUpdate:modelValue": ($event: any) => (($setup.selectedAnswers[index]) = $event),
              options: $setup.answers,
              optionLabel: "label_it",
              placeholder: "Seleziona",
              class: "col-12 m-0 p-0"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
          ])
        ])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_SubmitBtn, {
          label: "Ok",
          disabled: !$setup.enableSubmitBtn,
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.enableSubmitBtn ? $setup.next() : false))
        }, null, 8, ["disabled"])
      ])
    ])
  ], 64))
}