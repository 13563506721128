import Vuex from 'vuex'
import appModule, { AppState } from './modules/app'
import authModule, { AuthState } from './modules/auth'
import questionnairesModule, { QuestionnaireState } from './modules/questionnaires';
import reportsModule, { ReportState } from './modules/reports';

export interface State {
  app: AppState;
  auth: AuthState;
  questionnaires: QuestionnaireState;
  reports: ReportState
}

export const store = new Vuex.Store<State>({
  strict: true,
  modules: {
    app: appModule,
    auth: authModule,
    questionnaires: questionnairesModule,
    reports: reportsModule,
  }
})