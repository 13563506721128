import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    type: "submit",
    label: _ctx.label,
    class: _normalizeClass({'w-100': _ctx.width === 'w-100', 'mt-4': true}),
    disabled: _ctx.disabled
  }, null, 8, ["label", "class", "disabled"]))
}