import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "answer-multi grid align-items-center justify-content-between mb-4" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "col-6 lg:col-4 number-max-width" }
const _hoisted_4 = { class: "p-inputgroup" }
const _hoisted_5 = { class: "col-12 m-0 p-0 mb-4" }
const _hoisted_6 = { class: "flex justify-content-end mt-4 lg:mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_SubmitBtn = _resolveComponent("SubmitBtn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.answers, (answer, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-12 m-0 p-0",
        key: answer.id
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(answer.labels.label_it), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_InputNumber, {
                modelValue: $setup.selectedAnswers[index],
                "onUpdate:modelValue": ($event: any) => (($setup.selectedAnswers[index]) = $event),
                class: "input-number",
                showButtons: "",
                buttonLayout: "horizontal",
                incrementButtonIcon: "pi pi-plus",
                decrementButtonIcon: "pi pi-minus",
                min: 0,
                max: 3
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ])
          ])
        ])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_SubmitBtn, {
          label: "Ok",
          disabled: !$setup.enabledSubmitBtn,
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.enabledSubmitBtn ? $setup.next() : false))
        }, null, 8, ["disabled"])
      ])
    ])
  ], 64))
}