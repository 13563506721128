
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";

import moment from "moment";

import { showLoader } from "@/helpers/helpers";
import router from "@/router";
import { QUESTIONNAIRES_GETTERS } from "@/store/modules/questionnaires";

export default defineComponent({
  name: "OnboardingStep2View",

  setup() {
    const store = useStore();

    // * Computed
    const currentQuestionnaire = computed(
      () => store.getters[QUESTIONNAIRES_GETTERS.CURRENT_QUESTIONNAIRE]
    );

    // * Data
    const language = ref({ label: "Italiano", value: "it" });
    const languageOptions = [
      { label: "Italiano", value: "it" },
      // TODO: { label: 'Inglese', value: 'en' },
    ];

    // * Methods
    // const firstQuestion = function () {
    //   showLoader(store);

    //   store.dispatch(QUESTIONNAIRES_ACTIONS.FIRST_QUESTION).then(() => {
    //     hideLoader(store);
    //   })
    //     .catch((err) => {
    //       hideLoader(store);
    //       errorCallback(toast, err);
    //     });
    // }

    const startQuestionnaire = function () {
      showLoader(store);

      // * --> Inizio questionario
      router.push({ name: "questionario" });
    };

    return {
      // * Computed
      currentQuestionnaire,

      // * Data
      language,
      languageOptions,

      // * Methods
      startQuestionnaire,

      // * Utils
      moment,
    };
  },
});
