
import { computed, defineComponent, nextTick, ref } from "vue";
import { useRoute } from "vue-router";
import { retrieveReportIdFromUrl } from "@/helpers/report";

import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { REPORTS_GETTERS, REPORT_ACTIONS } from "@/store/modules/reports";
import { Report } from "@/models/Report";
import { hideLoader } from "@/helpers/loader";
import { errorCallback } from "@/helpers/helpers";

export default defineComponent({
  name: "ReportView",

  setup() {
    // * Helpers
    const store = useStore();
    const toast = useToast();

    // * Data
    const route = useRoute();

    // * Computed
    const report = computed(
      (): Report => store.getters[REPORTS_GETTERS.CURRENT_REPORT]
    );

    const labels = [
      "Carichi di cura",
      "Economico - Finanziarie",
      "Salute e benessere",
      "Mobilità e trasporto",
      "Formazione",
      "Tempo libero",
    ];

    const tabs = ref();

    const chartSections = computed(() => {
      return {
        chartData: {
          datasets: [
            {
              data: report.value != undefined ? report.value.sections : [],
              backgroundColor: [
                "#cd1867",
                "#DB4CB3",
                "#EABB3D",
                "#B958D8",
                "#a1c653",
                "#009265",
              ],
            },
          ],
          labels: labels,
        },
        chartOptions: {
          // responsive: true,
          maintainAspectRatio: 0.5,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem, _) => {
                  return `${tooltipItem["formattedValue"]}%`;
                },
              },
            },
          },
        },
      };
    });

    const question1ChartData = computed(() => {
      const data: Array<number> = [];
      const labels: Array<number> = [];

      const percentage: Array<string> = [];
      if (report.value != undefined) {
        report.value.answers["1"].answers.forEach((item) => {
          data.push(item.total);
          labels.push(item.label);

          percentage.push(item.percentage);
        });
      }

      return {
        chartData: {
          datasets: [
            {
              data: data,
              // backgroundColor: "#03989E",
              backgroundColor: ["#6EF0D2", "#DB4CB3", "#EABB3D", "#F0A68F"],
            },
          ],
          labels: labels,
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "y",
          plugins: {
            title: {
              display: true,
              text: `In quale fascia di età ti collochi? - Risposte: ${report.value.answers["1"].total}`,
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  return `${percentage[tooltipItem["dataIndex"]]}%`;
                },
                title: (tooltipItem) => {
                  return `${data[tooltipItem[0]["dataIndex"]]} risposte`;
                },
              },
            },
          },
        },
      };
    });

    const question2ChartData = computed(() => {
      const data: Array<number> = [];

      const labels: Array<number> = [];
      const percentage: Array<string> = [];
      if (report.value != undefined) {
        report.value.answers["2"].answers.forEach((item) => {
          data.push(item.total);
          labels.push(item.label);
          percentage.push(item.percentage);
        });
      }

      return {
        chartData: {
          datasets: [
            {
              data: data,
              backgroundColor: ["#6EF0D2", "#DB4CB3", "#EABB3D", "#F0A68F"],
            },
          ],
          labels: labels,
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "y",
          plugins: {
            title: {
              display: true,
              text: `Genere - Risposte: ${report.value.answers["2"].total}`,
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  return `${percentage[tooltipItem["dataIndex"]]}%`;
                },
                title: (tooltipItem) => {
                  return `${data[tooltipItem[0]["dataIndex"]]} risposte`;
                },
              },
            },
            yAxes: [
              {
                afterFit: function (scaleInstance) {
                  scaleInstance.width = 100; // sets the width to 100px
                },
              },
            ],
          },
        },
      };
    });

    const question3ChartData = computed(() => {
      const data: Array<number> = [];

      const labels: Array<number> = [];
      const percentage: Array<string> = [];
      if (report.value != undefined) {
        report.value.answers["3"].answers.forEach((item) => {
          data.push(item.total);
          labels.push(item.label);
          percentage.push(item.percentage);
        });
      }

      return {
        chartData: {
          datasets: [
            {
              data: data,
              backgroundColor: ["#6EF0D2", "#DB4CB3", "#EABB3D", "#F0A68F"],
            },
          ],
          labels: labels,
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "y",
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: `Qual è la tua condizione familiare? - Risposte: ${report.value.answers["3"].total}`,
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  return `${percentage[tooltipItem["dataIndex"]]}%`;
                },
                title: (tooltipItem) => {
                  return `${data[tooltipItem[0]["dataIndex"]]} risposte`;
                },
              },
            },
          },
        },
      };
    });

    // * Methods
    const initReport = async function () {
      const reportId = retrieveReportIdFromUrl(route);
      if (reportId != "") {
        reportId;
        try {
          const result = await store.dispatch(REPORT_ACTIONS.SHOW, reportId);
          fillTab();
          return result;
        } catch (err) {
          hideLoader(store);
          errorCallback(toast, err);
        }
      }
    };

    const genericChartData = (question, bg_color) => {
      const data: Array<number> = [];

      const labels: Array<number> = [];
      const percentage: Array<string> = [];
      if (report.value != undefined) {
        question.answers.forEach((item) => {
          data.push(item.total);
          labels.push(item.label);
          percentage.push(item.percentage);
        });
      }

      return {
        chartData: {
          datasets: [
            {
              data: data,
              backgroundColor: bg_color,
            },
          ],
          labels: labels,
        },
        chartOptions: {
          // responsive: true,
          maintainAspectRatio: 0.5,
          plugins: {
            title: {
              display: true,
              text: `${question.question} - Risposte: ${question.total}`,
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  return `${percentage[tooltipItem["dataIndex"]]}%`;
                },
                title: (tooltipItem) => {
                  return `${data[tooltipItem[0]["dataIndex"]]} risposte`;
                },
              },
            },
          },
        },
      };
    };

    const onRender = () => {
      console.log("Chart rendered");
    };

    const onUpdate = () => {
      console.log("Chart updated");
    };

    const onDestroy = () => {
      console.log("Chart destroyed");
    };

    const fillTab = () => {
      tabs.value = [];
      labels.forEach((value, index) => {
        let tab = {
          title: value,
          content: [],
        };
        tabs.value.push(tab);
      });
      if (report.value.answers) {
        for (let key in report.value.answers) {
          switch (+key) {
            case 5:
            case 6:
            case 11:
              tabs.value[0].content.push(
                genericChartData(report.value.answers[key], "#cd1867")
              );
              break;
            case 20:
            case 23:
              tabs.value[1].content.push(
                genericChartData(report.value.answers[key], "#DB4CB3")
              );
              break;
            case 25:
            case 26:
            case 33:
            case 34:
              tabs.value[2].content.push(
                genericChartData(report.value.answers[key], "#EABB3D")
              );
              break;
            case 35:
            case 36:
            case 37:
            case 41:
              tabs.value[3].content.push(
                genericChartData(report.value.answers[key], "#B958D8")
              );
              break;
            case 45:
            case 47:
              tabs.value[4].content.push(
                genericChartData(report.value.answers[key], "#a1c653")
              );
              break;
            case 53:
            case 54:
              tabs.value[5].content.push(
                genericChartData(report.value.answers[key], "#009265")
              );
              break;
            default:
              break;
          }
        }
      }
    };

    // * Init
    nextTick(async () => {
      initReport();
    });

    return {
      // * Data
      chartSections,
      question1ChartData,
      question2ChartData,
      question3ChartData,
      genericChartData,
      report,
      tabs,

      onRender,
      onUpdate,
      onDestroy,

      // * Methods
    };
  },
});
