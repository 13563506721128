
import { computed, defineComponent, nextTick, ref } from "vue";

import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";

import { errorCallback, hideLoader, showLoader } from "@/helpers/helpers";
import router from "@/router";
import {
  QUESTIONNAIRES_ACTIONS,
  QUESTIONNAIRES_GETTERS,
} from "@/store/modules/questionnaires";
import { AnswerType, Question } from "@/models/Question";
import { Questionnaire } from "@/models/UserQuestionnaires";
import { Answer } from "@/models/Answer";

import { retrieveQuestionnaireIdFromUrl } from "@/helpers/questionnaire";
import { useRoute } from "vue-router";
import { AUTH_LOGOUT } from "@/store/modules/auth";

export default defineComponent({
  name: "QuestionnaireView",
  setup() {
    // * Helpers
    const route = useRoute();
    const store = useStore();
    const toast = useToast();

    // * Computed
    const currentQuestionNumber = computed(
      () => questionnaire.value && questionnaire.value.question_ids_path.length + 1
    );
    const progressPercentage = computed(() => {
      if (questionnaire.value.is_completed) {
        return 100;
      }
      if (questionnaire.value) {
        // const answerNumber = questionnaire.value.question_ids_path.length;
        let percentage = (100 * questionnaire.value.current_question_number) / 54;
        // if (percentage < 1) {
        //   percentage = (100 * answerNumber) / 25;
        // }
        // if (percentage < 1) {
        //   percentage = (100 * answerNumber) / 30;
        // }
        return percentage;
      }
      else {
        return 0;
      }
    });
    const questionnaire = computed(
      (): Questionnaire =>
        store.getters[QUESTIONNAIRES_GETTERS.CURRENT_QUESTIONNAIRE]
    );
    const questionnaireId = computed(
      (): Questionnaire =>
        store.getters[QUESTIONNAIRES_GETTERS.QUESTIONNAIRE_ID]
    );
    const question = computed(
      (): Question | null =>
        store.getters[QUESTIONNAIRES_GETTERS.CURRENT_QUESTION]
    );

    // * Data
    const answer = ref<Answer | null>(null);

    // * Methods
    const fetchAllQuestions = async function () {
      showLoader(store);
      try {
        const result = await store.dispatch(
          QUESTIONNAIRES_ACTIONS.FETCH_ALL_QUESTIONS
        );
        hideLoader(store);
        return result;
      } catch (err) {
        hideLoader(store);
        errorCallback(toast, err);
      }
    };

    const fetchQuestionnaire = async function () {
      showLoader(store);
      try {
        const result = await store.dispatch(
          QUESTIONNAIRES_ACTIONS.SHOW
        );
        hideLoader(store);
        return result;
      } catch (err) {
        hideLoader(store);
        errorCallback(toast, err);
      }
    };

    const firstQuestion = async function () {
      showLoader(store);
      try {
        const result = await store.dispatch(
          QUESTIONNAIRES_ACTIONS.FIRST_QUESTION
        );
        hideLoader(store);
        return result;
      } catch (err) {
        hideLoader(store);
        errorCallback(toast, err);
      }
    };

    const initQuestionnaire = async function () {
      const argQuestionnaireId = retrieveQuestionnaireIdFromUrl(route);
      if (argQuestionnaireId != "") {
        // Ripulisco il localStorage e faccio logout
        localStorage.clear();
        await store.dispatch(AUTH_LOGOUT);
        
        // Salvo l'id del questionario
        store.dispatch(QUESTIONNAIRES_ACTIONS.STORE_ID, argQuestionnaireId);

        // Recupero il questionario e le info sull'azienda
        await fetchQuestionnaire();
        // Verifico che non sia ancora stato completato, 
        // ? altrimenti lo mando all'ultima view?

        

        // * --> LoginView
        router.push({ name: "login" });
        // * --> Onboarding - Step 1
        // // Redirect verso l'onboarding perché arrivo da link /questionario/:id
        // router.push({ name: "onboarding" });
      } else {
        if (!questionnaireId.value) {
          // Ripulisco il localStorage e faccio logout
          // localStorage.clear();
          // await store.dispatch(AUTH_LOGOUT);

          // * --> LoginView
          router.push({ name: "login" });
        }
        else {
          showLoader(store);
          await fetchAllQuestions();
          await firstQuestion();
          hideLoader(store);
        }
      }
    }

    const nextMulti = function (selectedAnswers: Array<Answer>) {
      if (question.value == null) {
        return;
      }
      const params = {
        user_questionnaire: {
          question_id: question.value.id,
          answer_ids: selectedAnswers.map((answer) => answer.id),
        },
      };
      showLoader(store);
      store
        .dispatch(QUESTIONNAIRES_ACTIONS.NEXT_QUESTION, params)
        .then(() => {
          hideLoader(store);
        })
        .catch((err) => {
          hideLoader(store);
          errorCallback(toast, err);
        });
    };
    const nextMultiNumber = function (
      selectedAnswers: Array<Map<string, any>>
    ) {
      if (question.value == null) {
        return;
      }
      const params = {
        user_questionnaire: {
          question_id: question.value.id,
          answer_values: selectedAnswers,
        },
      };
      showLoader(store);
      store
        .dispatch(QUESTIONNAIRES_ACTIONS.NEXT_QUESTION, params)
        .then(() => {
          hideLoader(store);
        })
        .catch((err) => {
          hideLoader(store);
          errorCallback(toast, err);
        });
    };
    const nextSingle = function (selectedAnswer: Answer) {
      if (question.value == null) {
        return;
      }
      const params = {
        user_questionnaire: {
          other_text: selectedAnswer.other_text,
          question_id: question.value.id,
          answer_id: selectedAnswer.id,
        },
      };
      showLoader(store);
      store
        .dispatch(QUESTIONNAIRES_ACTIONS.NEXT_QUESTION, params)
        .then(() => {
          hideLoader(store);
        })
        .catch((err) => {
          hideLoader(store);
          errorCallback(toast, err);
        });
    };

    const nextSingleFor = function (selectAnswers: Array<Map<string, any>>) {
      if (question.value == null) {
        return;
      }

      const params = {
        user_questionnaire: {
          question_id: question.value.id,
          answer_values: selectAnswers,
        },
      };
      showLoader(store);
      store
        .dispatch(QUESTIONNAIRES_ACTIONS.NEXT_QUESTION, params)
        .then(() => {
          hideLoader(store);
        })
        .catch((err) => {
          hideLoader(store);
          errorCallback(toast, err);
        });
    };

    const nextRange = nextSingle;
   
    // * Init
    nextTick(async () => {
      initQuestionnaire();
    });
    
    return {
      // * Computed
      currentQuestionNumber,
      questionnaire,
      question,
      progressPercentage,
      AnswerType,

      // * Data
      answer,

      // * Methods
      nextMulti,
      nextMultiNumber,
      nextRange,
      nextSingle,
      nextSingleFor,
    };
  },
});
