
import { computed, defineComponent, nextTick, reactive, ref } from "vue";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { email, sameAs, requiredIf } from "@vuelidate/validators";

import { errorCallback, hideLoader, showLoader, showToast } from "@/helpers/helpers";
import router from "@/router";
import { Questionnaire } from "@/models/UserQuestionnaires";
import {
  QUESTIONNAIRES_ACTIONS,
  QUESTIONNAIRES_GETTERS,
} from "@/store/modules/questionnaires";

export default defineComponent({
  name: "TestView",

  setup() {
    const store = useStore();
    const toast = useToast();

    // * Data
    const formStep = ref(2);

    const formState = reactive({
      email: "",
    });

    const formRules = {
      email: {
        required: true,
        email,
      },
    };

    const formSubmitted = ref(false);
    const submitBtnStep2Disabled = ref(true);

    const v$ = useVuelidate(formRules, formState);

    // * Computed
    const currentUser = computed(() => store.getters["auth/currentUser"]);

    const questionnaire = computed(
      (): Questionnaire => store.getters[QUESTIONNAIRES_GETTERS.CURRENT_QUESTIONNAIRE]
    );

    const handleSubmit = (isFormValid) => {
      formSubmitted.value = true;

      if (!isFormValid) {
        return;
      }

      showLoader(store);

      store
        .dispatch(QUESTIONNAIRES_ACTIONS.TEST, formState.email)
        .then(() => {
          hideLoader(store);

          showToast(toast, {
            message:
              "Abbiamo inviato all'utente le mail per accedere al questionario di test.",
            title: "Questionario creato",
          });
        })
        .catch((err) => {
          hideLoader(store);
          showToast(toast, { message: err.data.errors[0], error: true });
        });
    };

    return {
      formStep,
      formSubmitted,

      questionnaire,
      submitBtnStep2Disabled,
      v$,

      // * Computed
      currentUser,

      handleSubmit,
    };
  },
});
