import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10b75141"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "loader-container",
  class: "flex flex-wrap align-content-center justify-content-center h-screen w-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout))),
    _createVNode(_component_Toast, { position: "top-right" }),
    (_ctx.isLoaderVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ProgressSpinner, {
            id: "loader",
            strokeWidth: "4",
            animationDuration: ".5s"
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}