
import { Answer } from "@/models/Answer";
import { computed, ref } from "vue";

export default {
  name: "MultiSelect",

  // * Properties
  props: {
    answersList: {
      required: true,
      type: Array
    }, 

    maxAnswers: {
      required: false,
      type: Number
    },

    multiNo: {
      default: false,
      required: false,
      type: Boolean
    }, 
    
    width: {
      default: "",
      type: String,
    },
  },

  // * Emits
  emits: ['next'],

  // * Init
  setup(props, { emit }) {
    // * Computed
    const answers = computed(() => props.answersList as Array<Answer>);
    const lastAnswer = computed(() => answers.value.length > 0 ? answers.value[answers.value.length - 1] : null);
    const enabledSubmitBtn = computed(() => selectedAnswers.value.length > 0 && (!otherAnswerSelected.value || otherVal.value.length > 0 ));
    

    // * Data
    const selectedAnswers = ref<Array<Answer>>([]);
    const otherAnswerSelected = ref(false);
    const otherVal = ref('');

    // * Methods
    const next = function() {
      emit("next", selectedAnswers.value);
    }

    const toggleSelectAnswer = function (answer: Answer)  {
      
      const index = selectedAnswers.value.indexOf(answer);
      
      if (index > -1) {
        selectedAnswers.value.splice(index, 1);
      }
      else {
        if (props.maxAnswers != null && props.maxAnswers <= selectedAnswers.value.length ) {
          return;
        }
        selectedAnswers.value.push(answer);
      }
      
      if (props.multiNo && lastAnswer.value != null && (lastAnswer.value as Answer).id == answer.id) {
        selectedAnswers.value = [answer];
      }
      else if (props.multiNo && lastAnswer.value != null && (lastAnswer.value as Answer).id != answer.id) {
        // Sto cliccando una selezione non NO e lo rimuovo
        const indexNo = selectedAnswers.value.indexOf(lastAnswer.value);
        if (indexNo > -1) {
          selectedAnswers.value.splice(indexNo, 1);
        }
      }

      otherAnswerSelected.value = false;
      selectedAnswers.value.forEach((answer) => {
        otherAnswerSelected.value = otherAnswerSelected.value || ((answer as Answer).open_answer ?? false);
      });
    }

    return {
      // * Computed
      answers,
      enabledSubmitBtn,

      // * Data
      otherAnswerSelected,
      otherVal,
      selectedAnswers,

      // * Methods
      next,
      toggleSelectAnswer,
    }
  }
}
