import { User } from "@/models/User"
import { apiPost, storeHeaders } from "./api-service";

const USER_KEY = 'user';

export class AuthService {
  static async login(params: Map<string, any>): Promise<User> {
    const response = await apiPost("/auth/questionnaire_login", params);

    // Salvo gli header in sessione
    storeHeaders(JSON.stringify(response.headers));
    // Salvo l'utente nel localStorage
    AuthService.storeCurrentUser(response.data.user as User);

    return response.data.user as User;
  }

  static async sendCredentials(params: Map<string, any>): Promise<any> {
    await apiPost("/users/send_credentials", params);
    return;
  }

  static storeCurrentUser(user: User) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  static logout() {
    localStorage.removeItem(USER_KEY);
    storeHeaders(null);
  }
}