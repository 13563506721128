
import { defineComponent, ref } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { errorCallback, hideLoader, showLoader } from "@/helpers/helpers";
import { QUESTIONNAIRES_ACTIONS } from "@/store/modules/questionnaires";

export default defineComponent({
  name: "OnboardingView",

  setup() {
    // * Helpers
    const store = useStore();
    const toast = useToast();

    // * Data
    const language = ref({ label: "Italiano", value: "it" });
    const languageOptions = [
      { label: "Italiano", value: "it" },
      { label: "Inglese", value: "en" },
    ];

    // * Methods
    const firstQuestion = async function () {
      showLoader(store);

      try {
        const result = await store.dispatch(
          QUESTIONNAIRES_ACTIONS.FIRST_QUESTION
        );
        return result;
      } catch (err) {
        errorCallback(toast, err);
      } finally {
        hideLoader(store);
      }
    };

    const goToOnboarding2 = async function () {
      // Inizializo il questionario perché arrivo dalla view di Onboarding
      // FIXME: fare chiamata per ottenere solo show questionario
      await firstQuestion();

      // * --> Onboarding - Step 2
      router.push({ name: "onboarding-step2" });
    };

    return {
      // * Data
      language,
      languageOptions,

      // * Methods
      goToOnboarding2,
    };
  },
});
