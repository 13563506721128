// import axios from "axios";
export const HIDE_LOADER = "app/hideLoader"
export const IS_LOADER_VISIBLE = "app/isLoaderVisible"
export const SHOW_LOADER = "app/showLoader"

export interface AppState {
  loaderVisible: boolean,
  toastVisible: boolean,
  questionnaireId: string | null,
}

export default {
  namespaced: true,

  state: (): AppState => ({
    loaderVisible: false,
    toastVisible: false,
    questionnaireId: null,
  }),

  actions: {
    hideLoader(context) {
      context.commit('setLoaderVisible', false)
    },

    showLoader(context) {
      context.commit('setLoaderVisible', true)
    },

    // // FIXME: da spostare in questionnarires
    // async storeQuestionnaireId(context: any, id: string) {
    //   context.commit('saveQuestionnaireId', id);
    // }
  },

  getters: {
    isLoaderVisible: (state: AppState): boolean => state.loaderVisible,
  },

  mutations: {
    setLoaderVisible(state: AppState, visible: boolean) {
      state.loaderVisible = visible
    },


    saveQuestionnaireId(state: AppState, id: string) {
      state.questionnaireId = id;
    },
  }
}