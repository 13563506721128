import { RouteLocationNormalizedLoaded } from "vue-router";

const retrieveQuestionnaireIdFromUrl = function (route: RouteLocationNormalizedLoaded): string {
  if (route.params['id'] !== undefined) {
    return route.params['id'] as string;
  }
  return '';
}

export {
  retrieveQuestionnaireIdFromUrl
}
