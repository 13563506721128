import { toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f7cc13b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "grid p-4 pt-5 lg:min-h-screen align-content-center"
}
const _hoisted_2 = { class: "lg:col-offset-1 lg:col-6 questionnaire-max-width" }
const _hoisted_3 = { class: "col-12 grid m-0 pt-0 pb-0 lg:mb-4" }
const _hoisted_4 = {
  key: 0,
  class: "pi pi-info-circle"
}
const _hoisted_5 = {
  key: 0,
  class: "col-12 grid m-0 flex-column"
}
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = {
  id: "question-label",
  class: "mt-2"
}
const _hoisted_8 = { class: "col-12 grid m-0" }
const _hoisted_9 = { key: 6 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_SingleSelectAnswer = _resolveComponent("SingleSelectAnswer")!
  const _component_MultiSelectAnswer = _resolveComponent("MultiSelectAnswer")!
  const _component_RangeAnswer = _resolveComponent("RangeAnswer")!
  const _component_MultiNumberAnswer = _resolveComponent("MultiNumberAnswer")!
  const _component_SingleForAnswer = _resolveComponent("SingleForAnswer")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.questionnaire)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", null, [
              _createTextVNode(" Domanda " + _toDisplayString(_ctx.currentQuestionNumber) + " ", 1),
              (_ctx.question && _ctx.question.tooltip_it)
                ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_4, null, 512)), [
                    [
                      _directive_tooltip,
                      _ctx.question.tooltip_it,
                      void 0,
                      { top: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_ProgressBar, {
              value: _ctx.progressPercentage,
              showValue: false,
              class: "w-100",
              id: "questionnaire-progress"
            }, null, 8, ["value"])
          ]),
          (_ctx.question != null)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.question)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.question.labels.title_it ?? ''), 1),
                      _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.question.labels.label_it ?? ''), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.question.answer_type == _ctx.AnswerType.single)
                    ? (_openBlock(), _createBlock(_component_SingleSelectAnswer, {
                        key: 0,
                        answersList: _ctx.question.answers,
                        onNext: _ctx.nextSingle
                      }, null, 8, ["answersList", "onNext"]))
                    : (_ctx.question.answer_type == _ctx.AnswerType.multi)
                      ? (_openBlock(), _createBlock(_component_MultiSelectAnswer, {
                          key: 1,
                          answersList: _ctx.question.answers,
                          maxAnswers: _ctx.question.max_answers,
                          onNext: _ctx.nextMulti
                        }, null, 8, ["answersList", "maxAnswers", "onNext"]))
                      : (_ctx.question.answer_type == _ctx.AnswerType.multi_no)
                        ? (_openBlock(), _createBlock(_component_MultiSelectAnswer, {
                            key: 2,
                            answersList: _ctx.question.answers,
                            onNext: _ctx.nextMulti,
                            multiNo: true
                          }, null, 8, ["answersList", "onNext"]))
                        : (_ctx.question.answer_type == _ctx.AnswerType.range)
                          ? (_openBlock(), _createBlock(_component_RangeAnswer, {
                              key: 3,
                              answersList: _ctx.question.answers,
                              onNext: _ctx.nextRange
                            }, null, 8, ["answersList", "onNext"]))
                          : (_ctx.question.answer_type == _ctx.AnswerType.multi_number)
                            ? (_openBlock(), _createBlock(_component_MultiNumberAnswer, {
                                key: 4,
                                answersList: _ctx.question.answers,
                                onNext: _ctx.nextMultiNumber
                              }, null, 8, ["answersList", "onNext"]))
                            : (_ctx.question.answer_type == _ctx.AnswerType.single_for)
                              ? (_openBlock(), _createBlock(_component_SingleForAnswer, {
                                  key: 5,
                                  answersList: _ctx.question.answers,
                                  for_question_labels: _ctx.questionnaire.single_for_questions,
                                  onNext: _ctx.nextSingleFor
                                }, null, 8, ["answersList", "for_question_labels", "onNext"]))
                              : (_openBlock(), _createElementBlock("pre", _hoisted_9, _toDisplayString(_ctx.question), 1))
                ])
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}