import { Answer } from "./Answer";

export interface Question {
  answers: Array<Answer>,
  answer_type: AnswerType,
  id: string,
  labels: QuestionLabel,
  max_answers: number | null,
  section_number: number,
  tooltip_it?: string
}

export interface QuestionLabel {
  label_en: string | null,
  label_it: string | null,
  title_en: string | null,
  title_it: string | null,
  tooltip_en: string | null
  tooltip_it: string | null
}

export enum AnswerType {
  multi = 'multi',
  multi_no = 'multi_no',
  multi_number = 'multi_number', // - [1] +
  number = 'number', // 1, 2, 3, 4, 5 in a row
  range = 'range',
  single = 'single',
  single_for = 'single_for', // Figlio 1 - Single, Figlio 2 - Single
}

